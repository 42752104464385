<template>
  <div class="drivers flex">
    <div class="drivers__info">
      <h4 v-if="modal === 'driver'" class="text-center">Распределить</h4>
      <div v-if="driverOptionShow" class="flex mb-2 mt-3" style="width: 50%">
        <p class="city-add__key">Только свободные машины</p>
        <b-form-checkbox v-model="freeShow" class="city-add__checkbox" />
      </div>
      <div v-if="driverOptionShow" class="flex mb-2 mt-3" style="width: 50%">
        <p class="city-add__key">Автоподбор машин</p>
        <b-form-checkbox v-model="autoSelect" class="city-add__checkbox" />
      </div>
      <b-tabs content-class="mt-3">
        <b-tab title="Выбрать машину" @click="clickDriverOption(true)" active>
          <ul v-if="modal === 'driver' && transportList" class="drivers__items">
            <li
              v-for="(item, index) in filterModal"
              :key="index"
              class="drivers__item"
              :class="{ 'drivers__item-active': transportId === item.id }"
              @click="
                (transportId = item.id),
                  (pointIndex = { index: index, name: item.number }),
                  onClickTransport(item.id)
              "
            >
              <div
                v-if="item.status === 'free'"
                style="width: 10px; height: 10px; border-radius: 50% 50%; background-color: green"
              />
              <div
                v-if="item.status !== 'free'"
                style="width: 10px; height: 10px; border-radius: 50% 50%; background-color: red"
              />
              <div class="ml-1">
                <span v-if="item.tracked" class="drivers__box" />
                <p>{{ item.model?.name }} | {{ item.number }} | {{ item.body }}</p>
              </div>
            </li>
          </ul>
        </b-tab>
        <b-tab title="Выбрать водителя" @click="clickDriverOption(false)">
          <ul v-if="usersList" class="drivers__items">
            <li
              v-for="(item, index) in filterModalUsers"
              :key="index"
              class="drivers__item flex"
              :class="{ 'drivers__item-active': userId === item.id }"
              @click="userId = item.id"
            >
              <p>{{ item.name }} {{ item.surname }}</p>
              <div
                v-if="item.status !== 'free'"
                style="width: 10px; height: 10px; border-radius: 50% 50%; background-color: red"
              />
              <div
                v-if="item.status === 'free'"
                style="width: 10px; height: 10px; border-radius: 50% 50%; background-color: green"
              />
            </li>
          </ul>
        </b-tab>
      </b-tabs>
      <button class="drivers__btn btn-primary" @click="change(data.item.id)">Передать</button>
    </div>
    <MapBlock
      v-if="list && list.length"
      :key="'map3-component-' + mapUpdaterKey"
      class="drivers__map"
      :map-mark="list"
      :click-point="pointIndex"
      :modal="false"
      ref="mapRef"
      @onClickMarker="onClickMarker"
      @componentsUpdate="handleMapUpdate"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Modal",
  components: {
    MapBlock: () => ({
      component: import("../../../components/map/map3.vue"),
    }),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    modal: {
      type: String,
      default: null,
    },
    weight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mapUpdaterKey: 0,
      userId: null,
      transportId: null,
      freeShow: true,
      pointIndex: null,
      autoSelect: false,
      driverOptionShow: true,
    };
  },
  computed: {
    ...mapState(["usersList", "transportList", "mapTransports"]),
    list() {
      if (!this.mapTransports) {
        return [];
      } else {
        return this.mapTransports.map((e) => [e.coordination?.lat, e.coordination?.lng]);
      }
    },
    filterModal() {
      let productFilter = [...this.transportList];
      if (this.freeShow) {
        productFilter = productFilter.filter((i) => i.status === "free");
      }
      if (this.autoSelect) {
        productFilter = productFilter.filter(
          (i) => i.weight >= this.data.item.weight && i.volume >= this.data.item.volume
        );
      }
      // if (!this.freeShow) {productFilter = productFilter.filter(i => i.status !== 'free')}
      return productFilter;
    },
    filterModalUsers() {
      let productFilter = [...this.usersList.data];
      if (this.freeShow) {
        productFilter = productFilter.filter((i) => i.status === "free");
      }
      // if (!this.freeShow) {productFilter = productFilter.filter(i => i.status !== 'free')}
      return productFilter;
    },
  },
  created() {
    if (!this.mapTransports) this.$store.dispatch("getMapTransports");
  },
  mounted() {
    this.mapUpdaterKey++;
  },
  methods: {
    handleMapUpdate(key) {
      this.mapUpdaterKey = key;
    },
    change(value) {
      this.changeUser(value);
      this.changeTransport(value);
    },
    changeUser(value) {
      if (!this.userId) {
        return;
      }
      this.$api
        .post("/web/order/change/user", {
          order_id: value,
          user_id: this.userId,
          transport_id: this.transportId,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.$store.dispatch("getOrdersActiveProducts");
          this.$emit("close");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    changeTransport(value) {
      if (!this.transportId) {
        return;
      }
      this.$api
        .post("/web/order/change/transport", {
          order_id: value,
          transport_id: this.transportId,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.$store.dispatch("getOrdersActiveProducts");
          this.$emit("close");
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    clickDriverOption(val) {
      this.driverOptionShow = val;
    },
    onClickMarker(e) {
      this.transportId = e.data;
    },
    onClickTransport(id) {
      this.$refs.mapRef.onClickTransport(id);
    },
  },
  watch: {
    freeShow(value) {
      if (value) {
        this.$store.dispatch("getTransportList", { type: "get_not_occupied", weight: this.weight });
      } else {
        this.$store.dispatch("getTransportList", { type: "get_without_trailers" });
      }
    },
  },
};
</script>

<style scoped>
.drivers {
  height: 90%;
  width: 90%;
  padding: 15px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 5px;
}
.drivers__info {
  width: 40%;
  max-height: 100%;
  overflow-y: auto;
}
.drivers__map {
  min-width: 440px;
  width: 60%;
  height: 90%;
  margin: 10px;
}
.drivers__btn {
  min-width: 120px;
  height: 30px;
  margin-top: 18px;
  border-radius: 5px;
  font-size: 16px;
}
.drivers__items {
  border: 1px solid rgba(222, 222, 222, 0.68);
  padding: 0 5px;
  max-height: 350px;
  overflow-x: auto;
  height: auto;
  border-radius: 5px;
}
.drivers__text {
  margin: 16px 0;
  font-weight: 600;
  text-align: start;
}
.drivers__item {
  padding: 10px;
  height: 40px;
  box-sizing: border-box;
  text-align: start;
  display: flex;
  align-items: center;
  /*justify-content: flex-start;*/
  cursor: pointer;
}
.drivers__item:not(:last-child) {
  border-bottom: 1px solid #cbcbcb;
}

.drivers__item-active {
  background-color: #bee5eb;
}
.drivers__item:hover {
  background-color: #2bbec3;
}
.drivers__box {
  width: 10px;
  height: 10px;
  border-radius: 50% 50%;
  /* border: 8px solid green; */
  margin-right: 10px;
}
.search-modal {
  width: 70%;
  height: 30px;
  padding: 5px 10px;
  margin-bottom: 14px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
</style>
